
.main-box{
    align-items: center;
    margin-left: 100px;
    
    
}
.text-head{
    text-align: center;
}
.title{
    
    font-size: 16.66px;
    font-weight: 500;
    line-height: 22.9px;
    text-align: left;
    color:  #000000;
    ;

}
.description{
    font-size: 13.09px;
    font-weight: 400;
    line-height: 22.9px;
    text-align: left;
    color: #717579;
    margin-top: 10px;
    

}