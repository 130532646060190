/* Avenir Regular */
@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    src: local('Avenir'), local('Avenir-Regular'), url(../../public/fonts/Avenir-Regular.ttf) format('truetype');
  }
  
  /* Avenir Light */
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 300;
    src: local('Avenir Light'), local('Avenir-Light'), url(../../public/fonts/Avenir-Light.ttf) format('truetype');
  }
  
  /* Avenir Black */
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    src: local('Avenir Black'), local('Avenir-Black'), url(../../public/fonts/Avenir-Light.ttf) format('truetype');
  }
  
  /* Avenir Book */
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    src: local('Avenir Book'), local('Avenir-Book'), url(../../public/fonts/Avenir-Book.ttf) format('truetype');
  }
  
  /* Avenir Heavy */
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    src: local('Avenir Heavy'), local('Avenir-Heavy'), url(../../public/fonts/Avenir-Heavy.ttf) format('truetype');
  }
  