body {
  margin: 0;
  font-family: "Avenir, sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.contact {
  width: 100%;
  background-color: #902124;
  height: 100vh;
}
.image {
  width: 100vw;
  height: 450px;
}
h1 {
  color: white;
  font-weight: 500;
  font-size: 25px;
  font-family: sans-serif;
  margin-left: 50px;
}
button {
  width: 150px;
  height: 25px;
  border: 1px solid white;
}
button:hover {
  box-shadow: 5px 5px 5px maroon;
}
.contact-icons {
  display: flex;
  gap: 30px;
  float: right;
  margin-right: 30px;
  margin-top: 30px;
}
.icons {
  width: 20px;
}
.icons:nth-child(2) {
  border-radius: 50%;
}
.banner {
  background-color: #902124;
  display: flex;
  color: white;
  height: 130px;
  /* gap: 200px; */
  /* align-items: center; */
}
.innovative {
  width: 250px;
}
.innovative h1 {
  font-size: 23px;
  display: none;
}
.blog {
  font-size: 25px;
  font-weight: 600;
  margin-left: 0;
  margin-right: 250px;

  /* margin-left: auto;
  margin-right: auto; */
  /* justify-content: center; */
  /* align-items: center; */
  align-self: center;
}
hr {
  background-color: #902124;
  margin-bottom: 30px;
  margin-top: 30px;
}
h5 {
  color: grey;
}

.articles {
  margin-left: 30px;
  margin-right: 30px;
}
.articles h3 {
  font-weight: bold;
}
.line,
.filter {
  display: none;
}
.dropdown {
  margin-top: 20px;
}
select {
  box-shadow: 10px 5px 5px grey;
}
@media only screen and (min-width: 750px) {
  .image {
    width: 100%;
    height: 800px;
  }
  .contact {
    height: 970px;
  }

  /* button {
    width: 300px;
    height: 25px;
    border: 1px solid black;
  } */
  h1 {
    margin-top: 30px;
    font-size: 35px;
  }
  .articles {
    width: 60vw;
    margin-right: none;
  }
  .article {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .line {
    height: 80vh;
    width: 1vw;
    background-color: #902124;
    display: block;
    margin-bottom: 30px;
  }
  .filter {
    display: block;
    margin-right: 30px;
  }
  .filter h4 {
    justify-content: center;
    align-self: center;
    margin-bottom: 30px;
  }
  .filter hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .filter a {
    color: black;
  }
  .dropdown {
    display: none;
  }
  .innovative h1 {
    display: block;
  }
  .blog {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
  }
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(---noLine, 1);
}
